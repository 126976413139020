import { Box, Paper, Stack, Typography } from '@mui/material'
import { Constants } from '../../constants'
import useIsVertical from '../../hooks/useIsVertical'
import PageContainer from '../PageContainer/PageContainer'
import { image_desktop } from './assets'
import BackgroundComposition from './BackgroundComposition'

const Home = () => {
  const isVertical = useIsVertical()

  return (
    <PageContainer>
      <Box
        className='home-container'
        style={{
          position: 'relative',
          height: '100%',
        }}
      >
        <Paper
          square
          elevation={0}
          style={{
            position: 'relative',
            minHeight: '300px',
            height: isVertical ? '100%' : 'auto',
            width: '100%',
          }}
        >
          {isVertical ? (
            <BackgroundComposition />
          ) : (
            <>
              <img
                loading='eager'
                src={image_desktop}
                alt='our studio'
                width='100%'
                height='auto'
              />
              <Stack direction='row' justifyContent='space-between' paddingTop={2}>
                <Box>
                  <Typography variant='body2'>
                    {Constants.studioName}
                  </Typography>
                </Box>
                <Stack direction='row' spacing={2}>
                  <Typography
                    variant='body2'
                    component={'a'}
                    target='_top'
                    rel='noopener noreferrer'
                    href={`mailto:${Constants.contactEmail}`}
                    sx={{
                      textDecoration: 'unset',
                      color: 'inherit',
                    }}
                  >
                    {Constants.contactEmail}
                  </Typography>
                  <Typography variant='body2'>|</Typography>
                  <Typography variant='body2'>
                    {Constants.contactNumber}
                  </Typography>
                </Stack>
              </Stack>
            </>
          )}
        </Paper>
      </Box>
    </PageContainer>
  )
}

export default Home
