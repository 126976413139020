import { Box, Card, CardContent, Typography } from '@mui/material'
import { useState } from 'react'
import { Constants } from '../../../constants'

const ProjectCard = ({ title, image, order }) => {
  const [titleVisible, setTitleVisible] = useState(false)

  return (
    <Box
      p={1}
      sx={{
        order: `${order}`,
        width: { xs: 300, lg: 250 },
        height: { xs: 300, lg: 250 },
      }}
    >
      <Card
        onMouseOver={() => setTitleVisible(true)}
        onMouseOut={() => setTitleVisible(false)}
        square
        elevation={2}
        sx={{
          height: '100%',
          width: '100%',
          overflow: 'hidden',
          boxSizing: 'border-box',
        }}
      >
        <CardContent
          sx={{
            padding: '0px 0px 0px 0px !important',
            height: '100%',
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <Box
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              flexFlow: 'column nowrap',
              justifyContent: 'center',
              overflow: 'hidden',
              boxSizing: 'border-box',
            }}
          >
            <Box
              style={{
                height: '100%',
                width: '100%',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                overflow: 'hidden',
              }}
            >
              <img
                src={image.src}
                alt={image.alt}
                loading='lazy'
                style={{
                  height: '100%',
                  width: '100%',
                  objectFit: 'cover',
                }}
              />
            </Box>
          </Box>
          <Box
            paddingLeft={2}
            paddingRight={2}
            sx={{
              width: '100%',
              height: { xs: '150px', lg: '250px' },
              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'space-around',
              zIndex: 2,
              transform: titleVisible
                ? 'translate(0px, -100%)'
                : 'translate(0px, 0px)',
              color: `${Constants.onFrontColor}`,
              textAlign: 'left',
              transition: 'all .4s ease-in-out',
              backgroundColor: `${Constants.frontColor}`,
              boxSizing: 'border-box',
            }}
          >
            <Typography variant='h5'>{title}</Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

export default ProjectCard
