const Constants = {
  studioName: 'UVA Arquitectura',
  frontColor: '#bfff00',
  onFrontColor: '#0f172a',
  backColor: '#ed6db4',
  markerLocation: [36.72139114360279, -4.4196503538007965],
  officeAddress: 'C/ Duque de la Victoria 5, 3º. Málaga',
  contactEmail: 'estudio@uvaarquitectura.com',
  contactNumber: '(+34) 952 60 16 16',
}

export default Constants
