import { Box, Paper } from '@mui/material'
import ProjectCard from './ProjectCard'
import ExpansionCard from './ExpansionCard'
import {
  getAwards,
  getPlanning,
  getResidential,
  getTuristic,
} from '../../apiCalls'

const ImageGrid = ({ data = [] }) => {
  return (
    <Box
      data-testid='image-grid'
      style={{
        height: '100%',
        width: '100%',
        overflow: 'auto',
      }}
    >
      <Paper
        square
        elevation={0}
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexFlow: 'column nowrap',
          justifyContent: 'space-around',
          boxSizing: 'border-box',
        }}
      >
        <Box
          sx={{
            height: '100%',
            width: '100%',
            overflow: 'hidden',
            display: 'flex',
            flexFlow: 'column nowrap',
            justifyContent: 'center',
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexFlow: 'row wrap',
              justifyContent: 'space-evenly',
              overflow: 'auto',
            }}
          >
            {data.length > 0
              ? data.map((e, idx) => {
                  return (
                    <ProjectCard
                      key={idx}
                      title={e.name}
                      image={e.image}
                      order={e.order}
                    />
                  )
                })
              : 'No data'}
            <ExpansionCard
              title='residencial'
              fetcher={getResidential}
              order={8}
            />
            <ExpansionCard title='premios' fetcher={getAwards} order={10} />
            <ExpansionCard title='turístico' fetcher={getTuristic} order={13} />
            <ExpansionCard
              title='planeamiento'
              fetcher={getPlanning}
              order={15}
            />
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}

export default ImageGrid
