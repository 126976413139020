import { Box, ThemeProvider } from '@mui/material'
import { useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Header from './components/Header'
import { ApplicationContext } from './context/ApplicationContext'
import Architecture from './pages/Architecture'
// import BIM from './pages/BIM'
import Contact from './pages/Contact'
import Home from './pages/Home'
import Team from './pages/Team'
import theme from './theme/theme.js'

function App() {
  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <ThemeProvider theme={theme}>
      <ApplicationContext.Provider value={{ menuOpen, setMenuOpen }}>
        <BrowserRouter>
          <div className='App'>
            <Box
              style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                overflow: 'hidden',
              }}
            >
              <Header />
              <Routes>
                {/* INFO: wildcard catches a no-match*/}
                <Route path='*' element={<Home />} />
                <Route path='/architecture' element={<Architecture />} />
                {/* <Route path='/bim' element={<BIM />} /> */}
                <Route path='/contact' element={<Contact />} />
                <Route path='/team' element={<Team />} />
              </Routes>
            </Box>
          </div>
        </BrowserRouter>
      </ApplicationContext.Provider>
    </ThemeProvider>
  )
}

export default App
