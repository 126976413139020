import { Box, Paper } from '@mui/material'
import { useContext } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { ApplicationContext } from '../../context/ApplicationContext'
import MenuButton from './MenuButton/MenuButton'

const menuEntries = [
  {
    linkDirection: '/',
    linkDescription: 'Inicio',
  },
  {
    linkDirection: '/architecture',
    linkDescription: 'Arquitectura',
  },
  // {
  //   linkDirection: '/bim',
  //   linkDescription: 'BIM',
  // },
  {
    linkDirection: '/team',
    linkDescription: 'Nosotros',
  },
  {
    linkDirection: '/contact',
    linkDescription: 'Contacto',
  },
]

const Menu = () => {
  const { menuOpen, setMenuOpen } = useContext(ApplicationContext)

  return (
    <Box
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'space-around',
        backgroundColor: 'rgba(255,255,255, .9)',
      }}
    >
      <Paper
        square
        elevation={0}
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          alignItems: 'center',
          backgroundColor: 'transparent',
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {menuEntries.map((entry, k) => (
            <MenuButton
              key={k}
              onClick={() => setMenuOpen(!menuOpen)}
              linkComponent={RouterLink}
              linkDirection={entry.linkDirection}
              linkDescription={entry.linkDescription}
            />
          ))}
        </Box>
      </Paper>
    </Box>
  )
}

export default Menu
