import { Box, Paper, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { getStudioMembers } from '../../apiCalls'
import PageContainer from '../PageContainer/PageContainer'
import { atelierMembersGIF } from './assets'
import MemberCard from './MemberCard'

const Team = () => {
  const [teamMembers, setTeamMembers] = useState([])

  useEffect(() => {
    getStudioMembers().then((data) => setTeamMembers(data))
  }, [])

  return (
    <PageContainer
      title='Nosotros'
      description='Aprenda un poco más acerca de nosotros como equipo y de nuestra visión. Descubra quiénes son los integrantes de UVA Arquitectura'
    >
      <Box
        paddingBottom={2}
        style={{
          height: '100%',
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        <Paper
          square
          elevation={0}
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexFlow: 'column nowrap',
            justifyContent: 'flex-start',
            boxSizing: 'border-box',
          }}
        >
          <Typography variant='h3'>Nosotros</Typography>
          <Stack
            direction='column'
            justifyContent='flex-start'
            sx={{
              height: '100%',
              width: '100%',
              overflow: 'auto',
              boxSizing: 'border-box',
            }}
          >
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              // flexWrap='wrap'
              justifyContent={{ xs: 'stretch', md: 'space-between' }}
              alignItems='flex-end'
              spacing={2}
              paddingTop={2}
              paddingBottom={2}
              sx={{
                width: '100%',
              }}
            >
              <Box
                className='studio-image-container'
                flexGrow={2}
                maxWidth='600px'
                minWidth='300px'
              >
                <img
                  src={atelierMembersGIF}
                  alt='atelier-members'
                  width='100%'
                  height='auto'
                />
              </Box>
              <Box
                flexShrink={20}
                maxWidth={{ xs: '100%', sm: '350px', md: '400px', lg: '500px' }}
                justifySelf='flex-end'
              >
                <Typography variant='body1' textAlign='justify' paragraph>
                  La casa contemporánea, es una casa espaciosa, inundada de luz,
                  polivalente y a la vez tecnológicamente compleja. Precisa de
                  una arquitectura adaptada al lugar y respetuosa con el medio
                  ambiente, que habla más de las personas y como mejorar su
                  forma de vida.
                </Typography>
                <Typography variant='body1' textAlign='justify'>
                  En UVA arquitectura se unen trayectorias vitales
                  y profesionales diferentes y compatibles, animados por la
                  pasión a la Arquitectura, para enriquecer y aportar soluciones
                  a los nuevos retos residenciales, desde la tradición y la
                  tecnología.
                </Typography>
              </Box>
            </Stack>
            <Box
              sx={{
                paddingBottom: 2,
                maxWidth: { xs: '100%', md: '100%' },
                boxSizing: 'border-box',
              }}
            >
              <Stack
                direction='column'
                justifyContent='flex-start'
                alignItems='stretch'
              >
                {teamMembers &&
                  teamMembers.map((tm, idx) => (
                    <MemberCard
                      key={idx}
                      name={tm.name}
                      role={tm.role}
                      bio={tm.bio}
                      extended={tm.extended}
                      link={tm.link}
                    />
                  ))}
              </Stack>
            </Box>
          </Stack>
        </Paper>
      </Box>
    </PageContainer>
  )
}

export default Team
