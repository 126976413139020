import { Box, Paper } from '@mui/material'
import { useEffect, useState } from 'react'
import ImageGrid from '../../components/ImageGrid'
import { getAllProjects } from '../../apiCalls'
import PageContainer from '../PageContainer/PageContainer'

const Architecture = () => {
  const [projects, setProjects] = useState([])

  useEffect(() => {
    getAllProjects()
      .then((data) => setProjects(data))
      .catch((err) => alert(err))
  }, [])

  return (
    <PageContainer
      title='Arquitectura'
      description='Galería de Arquitectura. Proyectos de Arquitectura en el ámbito residencial, turístico, etcétera. Premios y galardones recibidos. Proyectos y obras de planeamiento urbano.'
    >
      <Box
        className='architecture-gallery-container'
        style={{
          boxSizing: 'border-box',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <Paper
          square
          elevation={0}
          style={{
            paddingTop: '20px',
            paddingBottom: '20px',
            height: '100%',
            width: '100%',
            boxSizing: 'border-box',
          }}
        >
          <ImageGrid data={projects} />
        </Paper>
      </Box>
    </PageContainer>
  )
}

export default Architecture
