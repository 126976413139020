import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Stack,
  Typography,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import React, { useState } from 'react'
import { Constants } from '../../../constants'

const MemberCard = React.memo(
  ({ name, role, bio = '', extended = '', link = undefined }) => {
    const [expanded, setExpanded] = useState(false)

    const navigateToLink = () => {
      window.open(link)
    }

    return (
      <Accordion
        elevation={0}
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
      >
        <AccordionSummary
          expandIcon={
            <AddIcon
              sx={{
                color: Constants.frontColor,
              }}
            />
          }
          aria-controls='panel-content'
          id='panel-header'
          sx={{
            flexDirection: 'row-reverse',
          }}
        >
          <Stack
            direction='column'
            justifyContent='space-around'
            alignItems='flex-start'
            paddingLeft={2}
            width='100%'
          >
            <Typography
              sx={{
                fontWeight: 600,
              }}
              variant='h6'
            >
              {name}
            </Typography>
            {role && <Typography variant='overline'>{role}</Typography>}
          </Stack>
          {link && (
            <Box justifySelf='flex-end' alignSelf='center'>
              <LinkedInIcon
                titleAccess={`Ir al perfil profesional de ${name} en LinkedIn`}
                onClick={(e) => {
                  e.stopPropagation()
                  navigateToLink()
                }}
                color='action'
                sx={{
                  cursor: 'pointer',
                }}
              />
            </Box>
          )}
        </AccordionSummary>
        <AccordionDetails
          sx={{
            paddingLeft: { sx: 2, sm: 7 },
          }}
        >
          <Box maxWidth='400px'>
            {bio && (
              <Box paddingBottom={2}>
                {bio.split('\n').map((p, idx) => (
                  <Typography
                    variant='body1'
                    textAlign='justify'
                    key={idx}
                    paragraph
                  >
                    {p}
                  </Typography>
                ))}
              </Box>
            )}

            {extended && (
              <>
                <Divider variant='middle' />
                <Box paddingTop={2}>
                  {extended.split('\n').map((p, idx) => (
                    <Typography
                      variant='body1'
                      textAlign='justify'
                      key={idx}
                      paragraph
                    >
                      {p}
                    </Typography>
                  ))}
                </Box>
              </>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    )
  }
)

export default MemberCard
