import { createTheme } from '@mui/material'

const theme = createTheme({
  typography: {
    fontFamily: [
      'Barlow Semi Condensed',
      'sans-serif'
    ].join(','),
  },
})

export default theme