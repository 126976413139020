import { Box, Typography } from '@mui/material'
import React from 'react'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV !== 'production') console.error(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return (
        // FIXME: substitute for error page
        <Box pt={2} pb={2}>
          <Typography variant='h4' color='error'>
            Oops! An error ocurred
          </Typography>
        </Box>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
