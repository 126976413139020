import { useTheme } from '@emotion/react'
import { Close } from '@mui/icons-material'
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Modal,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useEffect, useState } from 'react'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: 300, sm: 450, md: 600 },
  maxHeight: '80vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
}

const ExpansionModal = ({
  open,
  handleClose = () => {},
  title,
  fetcher = () => Promise.resolve(),
}) => {
  const [data, setData] = useState([])
  const theme = useTheme()
  const match = useMediaQuery(theme.breakpoints.up('sm'))

  useEffect(() => {
    fetcher().then((data) => setData(data))
  })

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby={`modal-modal-${title}`}
      aria-describedby={`modal-modal-${title}-expanded`}
    >
      <Box sx={style}>
        <Stack
          paddingBottom={2}
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography
            variant='h6'
            sx={{
              userSelect: 'none',
            }}
          >
            {title}
          </Typography>
          {!match && (
            <Box>
              <Close
                size='small'
                color='action'
                onClick={handleClose}
                sx={{ cursor: 'pointer' }}
              />
            </Box>
          )}
        </Stack>
        <Box
          paddingRight={4}
          sx={{
            overflow: 'auto',
          }}
        >
          {data && (
            <List
              sx={{
                listStyleType: 'disc',
                pl: 4,
              }}
            >
              {data.map((el, idx) => (
                <ListItem
                  disableGutters
                  key={idx}
                  sx={{ display: 'list-item' }}
                >
                  <ListItemText
                    primary={el}
                    sx={{
                      textAlign: 'justify',
                    }}
                  />
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      </Box>
    </Modal>
  )
}

export default ExpansionModal
