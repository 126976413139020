import { useLayoutEffect, useState } from 'react'

const useIsVertical = () => {
  const [isVertical, setIsVertical] = useState(false)

  const onResize = () => {
    const height = window.innerHeight
    const width = window.innerWidth

    height > width ? setIsVertical(true) : setIsVertical(false)
  }

  useLayoutEffect(() => {
    onResize()
  }, [])

  useLayoutEffect(() => {
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  return isVertical
}

export default useIsVertical
