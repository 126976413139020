import { PROJECTS } from './projects'

const AWARDS = require('./awards.json')
const RESIDENTIAL = require('./residential.json')
const TURISTIC = require('./turistic.json')
const PLANNING = require('./planning.json')
const MEMBERS = require('./members.json')

export const getAllProjects = () => {
  return new Promise((resolve, _) => {
    resolve(PROJECTS)
  })
}

export const getStudioMembers = () => {
  return new Promise((resolve, _) => {
    resolve(MEMBERS.data)
  })
}

export const getAwards = () => {
  return new Promise((resolve) => {
    resolve(AWARDS.data)
  })
}

export const getResidential = () => {
  return new Promise((resolve) => {
    resolve(RESIDENTIAL.data)
  })
}

export const getTuristic = () => {
  return new Promise((resolve) => {
    resolve(TURISTIC.data)
  })
}

export const getPlanning = () => {
  return new Promise((resolve) => {
    resolve(PLANNING.data)
  })
}
