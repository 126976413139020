import { Box, Paper, Stack, Typography } from '@mui/material'
import LocationMap from '../../components/LocationMap'
import { Constants } from '../../constants'
import BusinessIcon from '@mui/icons-material/Business'
import MailIcon from '@mui/icons-material/Mail'
import PhoneIcon from '@mui/icons-material/Phone'
import PageContainer from '../PageContainer/PageContainer'

const Contact = () => {
  return (
    <PageContainer
      title='Contacto'
      description='Póngase en contacto con nosotros a través de correo electrónico, vía telefónica o concertando una visita a nuestro estudio.'
    >
      <Box
        style={{
          height: '100%',
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        <Paper
          square
          elevation={0}
          sx={{
            height: '100%',
            width: '100%',
          }}
        >
          <Box
            sx={{
              textAlign: 'justify',
            }}
          >
            <Typography variant={'h3'}>Contacto</Typography>
            <Box paddingTop={2} paddingBottom={2}>
              <Stack
                paddingBottom={1}
                direction='row'
                alignItems='center'
                gap={1}
              >
                <BusinessIcon />
                <Typography variant={'h6'}>
                  {Constants.officeAddress}
                </Typography>
              </Stack>
              <Stack
                paddingBottom={1}
                direction='row'
                alignItems='center'
                gap={1}
              >
                <MailIcon />
                <Typography
                  component={'a'}
                  target='_top'
                  rel='noopener noreferrer'
                  href={`mailto:${Constants.contactEmail}`}
                  variant={'h6'}
                  sx={{
                    textDecoration: 'unset',
                    color: 'inherit',
                  }}
                >
                  {Constants.contactEmail}
                </Typography>
              </Stack>
              <Stack
                paddingBottom={1}
                direction='row'
                alignItems='center'
                gap={1}
              >
                <PhoneIcon />
                <Typography variant={'h6'}>
                  {Constants.contactNumber}
                </Typography>
              </Stack>
            </Box>
            <Box
              style={{
                height: '300px',
                maxWidth: '500px',
                width: '100%',
                zIndex: 2,
              }}
            >
              <LocationMap
                mapCenter={Constants.markerLocation}
                markerText={Constants.officeAddress}
              />
            </Box>
          </Box>
        </Paper>
      </Box>
    </PageContainer>
  )
}

export default Contact
