import { Box, Container, Paper, Stack } from '@mui/material'
import { useCallback, useContext } from 'react'
import { ApplicationContext } from '../../context/ApplicationContext'
import Menu from '../Menu'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import Constants from '../../constants/Constants'
import CircleIcon from '@mui/icons-material/Circle'

const uvaLogo = require('./assets/uva_logo.png')

const Header = () => {
  const { menuOpen, setMenuOpen } = useContext(ApplicationContext)
  const navigate = useNavigate()

  const navigateAndCloseMenu = useCallback(() => {
    navigate('/')
    setMenuOpen(false)
  }, [navigate, setMenuOpen])

  return (
    <>
      <Box
        paddingY={{ xs: 1, sm: 2 }}
        id='header'
        sx={{
          position: 'fixed',
          zIndex: 9,
          width: '100%',
        }}
      >
        <Container maxWidth='lg'>
          <Paper
            square
            elevation={0}
            style={{
              touchAction: 'none',
              backgroundColor: 'transparent',
            }}
          >
            <Stack
              direction='row'
              alignItems='center'
              justifyContent={'space-between'}
              gap={1}
              sx={{
                color: 'black',
              }}
            >
              <Box
                onClick={navigateAndCloseMenu}
                data-testid='atelier-title'
                sx={{
                  cursor: 'pointer',
                }}
              >
                <img
                  src={uvaLogo}
                  alt='uva_logo'
                  height={60}
                  aria-label='logo UVA Arquitectura'
                  title='UVA Arquitectura'
                />
              </Box>
              <Box
                onClick={() => setMenuOpen(!menuOpen)}
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  flexFlow: 'column nowrap',
                  justifyContent: 'space-around',
                  color: Constants.frontColor,
                  fontSize: '60px',
                }}
              >
                {!menuOpen ? (
                  <CircleIcon
                    fontSize='inherit'
                    aria-label='abrir menú'
                    titleAccess='abrir menú'
                  />
                ) : (
                  <CloseIcon
                    fontSize='inherit'
                    aria-label='cerrar menú'
                    titleAccess='cerrar menú'
                  />
                )}
              </Box>
            </Stack>
          </Paper>
        </Container>
      </Box>
      <Box
        style={{
          position: 'absolute',
          top: menuOpen ? 0 : '-100%',
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
          touchAction: 'none',
          zIndex: 7,
          overflow: 'hidden',
          boxSizing: 'border-box',
          transition: 'all .8s ease-in-out',
        }}
      >
        <Menu />
      </Box>
    </>
  )
}

export default Header
