const image00 = require('../assets/images/24_apartamentos_isabel_la_catolica_malaga.jpg')
const image01 = require('../assets/images/2viviendasy10estudios_en_calle_ronquillo_briceño_cordoba.jpg')
const image02 = require('../assets/images/35_viviendas_martinez_maldonado_malaga.jpg')
const image03 = require('../assets/images/6viviendas_callebuenosaires_cordoba.jpg')
const image04 = require('../assets/images/85viviendas_cañada_de_los_cardos_malaga.jpg')
const image05 = require('../assets/images/8viviendasylocalcomercialcallepozosdulces_malaga.jpg')
const image06 = require('../assets/images/98viviendas_vpo_soliva_malaga.jpg')
const image07 = require('../assets/images/centrobajaexigencia_sistemaprefabricadodesmontable_malaga.jpg')
const image08 = require('../assets/images/rehabilitacion12viviendasylocalcomercial_callecasapalma_malaga.jpg')
const image09 = require('../assets/images/16viviendas_la_yesera_fuengirola.jpg')
const image10 = require('../assets/images/vivenda_unifamiliar_benalmadena.jpg')
const image11 = require('../assets/images/vivienda_unifamiliar_reforma_ampliacion_benalamadena.jpg')

export const PROJECTS = [
  {
    name: '24 apartamentos en c/ Isabel la Católica (Málaga)',
    description: '24_apartamentos_isabel_la_catolica_malaga.jpg',
    order: 1,
    image: {
      src: image00,
      alt: '24_apartamentos_isabel_la_catolica_malaga.jpg',
    },
  },
  {
    name: '2 viviendas y 10 estudios en c/ Ronquillo Briceño (Córdoba)',
    description: '2viviendasy10estudios_en_calle_ronquillo_briceño_cordoba.jpg',
    order: 9,
    image: {
      src: image01,
      alt: '2viviendasy10estudios_en_calle_ronquillo_briceño_cordoba.jpg',
    },
  },
  {
    name: '35 viviendas en c/ Martínez Maldonado (Málaga)',
    description: '35_viviendas_martinez_maldonado_malaga.jpg',
    order: 5,
    image: {
      src: image02,
      alt: '35_viviendas_martinez_maldonado_malaga.jpg',
    },
  },
  {
    name: '6 viviendas en c/ Buenos Aires (Córdoba)',
    description: '6viviendas_callebuenosaires_cordoba.jpg',
    order: 4,
    image: {
      src: image03,
      alt: '6viviendas_callebuenosaires_cordoba.jpg',
    },
  },
  {
    name: '85 viviendas en Cañada de los Cardos (Málaga)',
    description: '85viviendas_cañada_de_los_cardos_malaga.jpg',
    order: 3,
    image: {
      src: image04,
      alt: '85viviendas_cañada_de_los_cardos_malaga.jpg',
    },
  },
  {
    name: '8 viviendas y local comercial en c/ Pozos Dulces (Málaga)',
    description: '8viviendasylocalcomercialcallepozosdulces_malaga.jpg',
    order: 6,
    image: {
      src: image05,
      alt: '8viviendasylocalcomercialcallepozosdulces_malaga.jpg',
    },
  },
  {
    name: '98 VPO en Soliva (Málaga)',
    description: '98viviendas_vpo_soliva_malaga.jpg',
    order: 2,
    image: {
      src: image06,
      alt: '98viviendas_vpo_soliva_malaga.jpg',
    },
  },
  {
    name: 'Centro Baja Exigencia, sistema prefabricado desmontable (Málaga)',
    description:
      'centrobajaexigencia_sistemaprefabricadodesmontable_malaga.jpg',
    order: 7,
    image: {
      src: image07,
      alt: 'centrobajaexigencia_sistemaprefabricadodesmontable_malaga.jpg',
    },
  },
  {
    name: 'Rehabilitación 2 viviendas y local comercial en c/ Casa Palma (Málaga)',
    description:
      'rehabilitacion12viviendasylocalcomercial_callecasapalma_malaga.jpg',
    order: 11,
    image: {
      src: image08,
      alt: 'rehabilitacion12viviendasylocalcomercial_callecasapalma_malaga.jpg',
    },
  },
  {
    name: '16 Viviendas y zonas verdes en La Yesera (Fuengirola)',
    description: '16viviendas_la_yesera_fuengirola.jpg',
    order: 12,
    image: {
      src: image09,
      alt: '16viviendas_la_yesera_fuengirola.jpg',
    },
  },
  {
    name: 'Vivienda unifamiliar, urbanización Montealto (Benalmádena)',
    description: 'vivenda_unifamiliar_benalmadena.jpg',
    order: 16,
    image: {
      src: image10,
      alt: 'vivenda_unifamiliar_benalmadena.jpg',
    },
  },
  {
    name: 'Vivienda unifamiliar, reforma y ampliación (Benalmádena)',
    description: 'vivienda_unifamiliar_reforma_ampliacion_benalamadena.jpg',
    order: 14,
    image: {
      src: image11,
      alt: 'vivienda_unifamiliar_reforma_ampliacion_benalamadena.jpg',
    },
  },
]
