import { Box, Card, CardContent, Typography } from '@mui/material'
import { useState } from 'react'
import { Constants } from '../../../constants'
import ExpansionModal from './ExpansionModal'

const ExpansionCard = ({ title, fetcher = () => Promise.resolve(), order }) => {
  const [expansionModalOpen, setExpansionModalOpen] = useState(false)
  const [backgroundColor, setBackgroundColor] = useState('none')

  return (
    <>
      <Box
        p={1}
        sx={{
          order: `${order}`,
          width: { xs: 300, lg: 250 },
          height: { xs: 300, lg: 250 },
        }}
      >
        <Card
          onMouseOver={() => setBackgroundColor(Constants.frontColor)}
          onMouseOut={() => setBackgroundColor('none')}
          onClick={() => setExpansionModalOpen(true)}
          square
          variant='outlined'
          sx={{
            height: '100%',
            width: '100%',
            overflow: 'hidden',
            boxSizing: 'border-box',
            borderColor: `${Constants.frontColor}`,
            backgroundColor: backgroundColor,
          }}
        >
          <CardContent
            sx={{
              position: 'relative',
              padding: '0px 0px 0px 0px !important',
              height: '100%',
              width: '100%',
              overflow: 'hidden',
            }}
          >
            <Box
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexFlow: 'column nowrap',
                justifyContent: 'center',
                overflow: 'hidden',
                boxSizing: 'border-box',
              }}
            >
              <Box
                style={{
                  height: '100%',
                  width: '100%',
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden',
                  color:
                    backgroundColor === `${Constants.frontColor}`
                      ? Constants.onFrontColor
                      : Constants.frontColor,
                }}
              >
                <Typography
                  fontWeight={900}
                  sx={{
                    fontSize: '150px',
                    lineHeight: '1',
                  }}
                >
                  +
                </Typography>
                <Typography variant='h4' paddingBottom={2}>
                  {title.toUpperCase()}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                position: 'absolute',
                bottom: 5,
                right: 10,
                cursor: 'pointer',
                color:
                  backgroundColor === `${Constants.frontColor}`
                    ? Constants.onFrontColor
                    : Constants.frontColor,
              }}
            >
              <Typography variant='body2' fontWeight={700}>
                [+ VER MÁS]
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <ExpansionModal
        open={expansionModalOpen}
        handleClose={() => setExpansionModalOpen(false)}
        title={title.toUpperCase()}
        fetcher={fetcher}
      />
    </>
  )
}

export default ExpansionCard
