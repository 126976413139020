import L from 'leaflet'
import { useEffect, useState } from 'react'

const LocationMap = ({ mapCenter = [36.72, -4.41], markerText = null }) => {
  const [map, setMap] = useState(null)

  useEffect(() => {
    // TODO: fix map already initialized error
    // TODO: fix dependency array error
    if (!map) {
      let c = mapCenter
      let m = L.map('map').setView(c, 16)
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(m)

      if (markerText) {
        L.marker(c).addTo(m).bindPopup(markerText).openPopup()
      }

      setMap(m)
    }
  }, [map, mapCenter, markerText])

  return (
    <div
      id='map'
      style={{
        height: '100%',
        width: '100%',
        zIndex: 'inherit'
      }}
    />
  )
}

export default LocationMap
