import { Box, Button, Typography } from '@mui/material'

const MenuButton = ({ onClick, linkComponent, linkDirection, linkDescription }) => {
  return (
    <Box p={1} onClick={() => onClick()}>
      <Button component={linkComponent} fullWidth to={linkDirection}>
        <Typography
          variant='h4'
          style={{
            color: 'black',
            fontWeight: '600',
          }}
        >
          {linkDescription}
        </Typography>
      </Button>
    </Box>
  )
}

export default MenuButton
