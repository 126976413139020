import { Box, Paper } from '@mui/material'
import useIsVertical from '../../../hooks/useIsVertical'
import { image_desktop, image_mobile } from '../assets'

const BackgroundImage = () => {
  const isVertical = useIsVertical()

  return (
    <Box
      data-testid='background-composition'
      paddingBottom={2}
      style={{
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
      }}
    >
      <Paper
        square
        elevation={0}
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
          display: 'flex',
          flexFlow: 'column nowrap',
          justifyContent: 'space-around',
          boxSizing: 'border-box',
          backgroundImage: isVertical
            ? `url(${image_mobile})`
            : `url(${image_desktop})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: isVertical ? 'center' : 'top center',
          backgroundSize: isVertical ? 'cover' : 'contain',
        }}
      ></Paper>
    </Box>
  )
}

export default BackgroundImage
