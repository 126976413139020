import { Container } from '@mui/material'
import { useLayoutEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import ErrorBoundary from './ErrorBoundary'

const PageContainer = ({ children, title, description = undefined }) => {
  const [paddingTop, setPaddingTop] = useState(0)

  useLayoutEffect(() => {
    const onResize = () => {
      let headerEl = document.getElementById('header')
      setPaddingTop(headerEl.offsetHeight)
    }

    onResize()

    window.addEventListener('resize', onResize)

    return () => window?.removeEventListener('resize', onResize)
  }, [])

  useLayoutEffect(() => {
    if (description) {
      const descriptionTag = document.querySelector('meta[name="description"]')
      if (descriptionTag) {
        descriptionTag.remove()
      }
    }
  }, [description])

  return (
    <>
      <Helmet>
        <title>{`UVA ${title ? title : 'Arquitectura'}`}</title>
        {description && <meta name='description' content={description} />}
      </Helmet>
      <Container
        maxWidth='lg'
        sx={{
          paddingTop: `${paddingTop}px`,
          width: '100%',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <ErrorBoundary>{children}</ErrorBoundary>
      </Container>
    </>
  )
}

export default PageContainer
